<template>
  <div class="q-mx-md">
    <q-range
      v-model="model"
      :color="$q.dark.isActive ? 'primary' : 'dark'"
      label-always
      label-text-color="light"
      :left-label-value="`$${model?.min}`"
      :max="computedMaxRange"
      :min="1"
      :right-label-value="`$${model?.max}`"
      size="lg"
      :step="step"
      thumb-size="24px"
      @click="onClick()"
      @update:model-value="onUpdate"
    />
  </div>
  <div class="q-my-sm q-pb-xs row">
    <div class="col">
      <q-input
        v-if="model"
        v-model.number="model.min"
        :debounce="2000"
        filled
        hide-bottom-space
        label="From"
        lazy-rules
        no-error-icon
        prefix="$"
        :rules="[
          (val) =>
            (val >= 0 && val < model.max) ||
            'please enter a price within range',
        ]"
        type="number"
      />
    </div>
    <div class="col">
      <q-input
        v-if="model?.max"
        v-model.number="model.max"
        :color="$q.dark.isActive ? 'secondary' : 'dark'"
        :debounce="2000"
        filled
        hide-bottom-space
        label="To"
        lazy-rules
        no-error-icon
        prefix="$"
        :rules="[
          (val) => val > model.min || 'please enter a price within range',
        ]"
        type="number"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, inject, onMounted, ref } from "vue";
import { debounce } from "quasar";
import { storeToRefs } from "pinia";
import { useColorStore } from "src/stores/color";
import { useFilterStore } from "src/stores/filter.js";

defineOptions({ name: "FilterRange" });

const emit = defineEmits(["modelChange"]);
const props = defineProps({
  filterId: { type: String, default: "" },
  filterLabel: { type: String, default: "" },
  filterModel: { type: Object, default: () => {} },
  handle: { type: String, default: "" },
  max: { type: Number, default: 1 },
  maxLabel: { type: String, default: "" },
  min: { type: Number, default: 0 },
  minLabel: { type: String, default: "" },
  type: { type: String, default: "collection" },
});

const bus = inject("bus");
const colorStore = useColorStore();
const { textColor } = storeToRefs(colorStore);
const filterStore = useFilterStore();
const { availableAllFiltersMaxPrice, availableCollectionFiltersMaxPrice } =
  storeToRefs(filterStore);

const computedMaxRange = computed(() => {
  return props.type === "collection"
    ? availableCollectionFiltersMaxPrice.value
    : availableAllFiltersMaxPrice.value;
});
const computedModel = computed(() => {
  return props.filterModel;
});
const model = ref(computedModel.value);
const step = 1;

const modelToQuery = function (modelValue) {
  const modelQuery = {
    [`filter.v.price.min`]: modelValue.min,
    [`filter.v.price.max`]: modelValue.max,
  };

  return modelQuery;
};

const emitModel = debounce((modelValue) => {
  emit("modelChange", {
    handle: props.handle,
    query: modelToQuery(modelValue),
  });
  bus.emit("filterAdd");
}, 1000);

const onClick = () => {
  bus.emit("showHideSounds");
};

const onUpdate = (value) => {
  bus.emit("showHideSounds");
  emitModel(value);
};

onMounted(() => {
  model.value = {
    min: props.min,
    max: props.max,
  };
});
</script>
