<template>
  <q-option-group
    v-model="model"
    :class="[
      `${filterId.split('.')[2]} ${filterId.split('.')[3]} justify-${justify}`,
      { drawer: drawer },
      {
        'text-lowercase text-small': filterId.startsWith(
          'filter.v.option.size'
        ),
      },
    ]"
    class="row text-caption"
    :color="fillColor"
    :dense="!$q.platform.is.mobile"
    :key="`q-option-group-${$route.fullPath}-${$route.query}`"
    :options="optionsIndexed"
    type="checkbox"
  >
    <template #label="option">
      <div
        :class="[{ disabled: option.count === 0 }, { row: drawer }]"
        class="relative-position"
      >
        <div class="relative-position">{{ option.label }}</div>
      </div>
    </template>
  </q-option-group>
</template>

<script setup>
import { computed, inject, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { i18n } from "src/boot/i18n";
import { useColorStore } from "src/stores/color";

defineOptions({ name: "FilterOptions" });

const emit = defineEmits(["modelChange"]);
const props = defineProps({
  dense: { type: Boolean, default: false },
  drawer: { type: Boolean, default: true },
  filterId: { type: String, default: "" },
  filterLabel: { type: String, default: "" },
  filterModel: { type: Object, default: () => {} },
  handle: { type: String, default: "" },
  justify: { type: String, default: "around" },
  options: { type: Object, default: () => {} },
});

const bus = inject("bus");
const { t } = i18n.global;
const colorStore = useColorStore();
const { fillColor } = storeToRefs(colorStore);

const computedModel = computed(() => {
  return props.filterModel;
});
const model = ref(computedModel.value);
const optionsIndexed = computed(() => {
  return props.options.map((option, index) => ({
    ...option,
    index,
    checkedIcon: t("icons.checkboxOn"),
    uncheckedIcon: t("icons.checkboxOff"),
  }));
});

const modelToQuery = (newModel) => {
  const modelQuery = {};
  if (newModel.length)
    newModel.forEach((filter) => {
      const [base, key, category, value, variant] = filter.split(".");
      const filterParam = variant
        ? `${base}.${key}.${category}.${value}`
        : `${base}.${key}.${category}`;
      if (!modelQuery[filterParam]) {
        modelQuery[filterParam] = [variant ? variant : value];
      } else {
        modelQuery[filterParam].push(variant ? variant : value);
      }
    });
  return modelQuery;
};

watch(model, (newModel, oldModel) => {
  newModel.length > oldModel.length
    ? bus.emit("filterAdd")
    : bus.emit("filterRemove");
  emit("modelChange", {
    handle: props.handle,
    query: modelToQuery(newModel),
  });
});
</script>

<style lang="scss">
.text-small {
  font-size: 10px;
}
.q-option-group.q-gutter-x-sm.gutter-x-none {
  margin-left: 0;
}

.q-option-group.q-option-group--inline:not(.option.size) > div,
.q-option-group.q-option-group--inline.product_type > div,
.q-option-group.q-option-group--inline.option.color > div,
.q-option-group.q-option-group--inline.vendor > div {
  margin-left: 0;
  position: relative;
  width: 50%;
}

.q-option-group.q-option-group--inline.size > div {
  margin-left: 0;
  position: relative;
  width: 33%;
}

.q-option-group:not(.drawer).color .q-checkbox,
.q-option-group:not(.drawer).product_type .q-checkbox,
.q-option-group:not(.drawer).vendor .q-checkbox {
  flex-direction: column;
  width: 100%;
}

.q-option-group.color .q-checkbox,
.q-option-group.product_type .q-checkbox,
.q-option-group.size .q-checkbox {
  width: 100%;
}

.q-option-group.vendor .q-checkbox {
  width: 100%;
}

.q-option-group.color.drawer > div,
.q-option-group.undefined.drawer > div {
  width: 47%;
}

.q-option-group.size.drawer > div {
  position: relative;
  width: 30%;
}

.q-option-group.vendor.drawer > div {
  width: 100%;
}

.q-option-group--inline.size > div {
  width: 30%;
}
</style>
