<template>
  <div>
    <filter-options
      v-if="filter.type === 'LIST'"
      drawer
      :filter-id="filter.id"
      :filter-label="filter.label"
      :filter-model="filter.model"
      :handle="
        type === 'collection' ? $route.params.collection : 'all-products'
      "
      justify="between"
      :options="filter.values"
      @model-change="onModelChange"
    />
    <filter-range
      v-else-if="filter.type === 'PRICE_RANGE'"
      :filter-id="filter.id"
      :filter-label="filter.label"
      :handle="$route.params.collection"
      :key="`${filter.min}-${filter.max}`"
      :max="filter.max"
      :max-label="filter.maxLabel"
      :min="filter.min"
      :min-label="filter.minLabel"
      @model-change="onModelChange"
    />
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import FilterOptions from "./FilterOptions.vue";
import FilterRange from "./FilterRange.vue";

defineOptions({ name: "FilterOptionsRange" });

const props = defineProps({
  filter: { type: Object, default: () => {} },
  maxHeight: { type: String, default: "400px" },
  open: { type: Boolean, default: false },
  type: { type: String, default: "collection" },
});

const router = useRouter();

const onModelChange = (model) => {
  const route = router.currentRoute.value;
  let to = {
    name: route.name,
    params: { ...route.params },
  };
  if (model.query)
    to = {
      ...to,
      query: {
        ...model.query,
      },
    };
  router.push(to);
};
</script>
