<template>
  <div>
    <q-list class="q-mb-xl">
      <q-item class="header-height">
        <q-item-section>
          <circle-text-header
            class="header-height"
            :header="t('filter.label')"
          />
          <div v-if="drawerView" class="absolute-right q-mr-sm">
            <q-btn flat :icon="t('icons.close')" round @click="onClick()" />
          </div>
        </q-item-section>
      </q-item>
      <q-list v-for="filter in queryModel" :key="filter.id">
        <q-separator />
        <q-item>
          <q-item-section>
            <q-item-label header :class="[sectionClass, textColor]">{{
              filter.label
            }}</q-item-label>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <filter-options-range :filter="filter" />
          </q-item-section>
        </q-item>
      </q-list>
    </q-list>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { i18n } from "src/boot/i18n";
import { useColorStore } from "src/stores/color";
import { useFilterStore } from "src/stores/filter";
import { useLayoutStore } from "src/stores/layout";
import CircleTextHeader from "src/components/circleText/CircleTextHeader.vue";
import FilterOptionsRange from "./FilterOptionsRange.vue";

defineOptions({ name: "FilterDrawer" });

const props = defineProps({
  headerClass: {
    type: String,
    default:
      "q-pl-none text-letterspace text-petite text-uppercase text-weight-thin",
  },
  sectionClass: {
    type: String,
    default:
      "q-pl-none text-overline text-petite text-uppercase text-weight-thin",
  },
  view: { type: String, default: "default" },
});

const { t } = i18n.global;
const colorStore = useColorStore();
const { textColor } = storeToRefs(colorStore);
const filterStore = useFilterStore();
const { collectionQueryModel } = storeToRefs(filterStore);
const layoutStore = useLayoutStore();
const { rightDrawerOpen, rightChildDrawerOpen } = storeToRefs(layoutStore);

const drawerView = computed(() => props.view === "drawer");

const queryModel = computed(() => {
  return collectionQueryModel.value;
});

const onClick = () => {
  !drawerView.value
    ? (rightDrawerOpen.value = false)
    : (rightChildDrawerOpen.value = false);
};
</script>
